@use "@sharks/scss/sharks" as sharks-scss-sharks;

@mixin animation-theme($theme) {

  $accent     : map-get($theme, accent);
  $warn       : map-get($theme, warn);
  $foreground : map-get($theme, foreground);

  ///////////////////////////////////////////// Shake /////////////////////////////////////////////

  .shake {
    animation : shake 5s infinite;
  }

  @keyframes shake {
    0% {
      transform : translate(1px, 1px) rotate(0deg);
    }
    1% {
      transform : translate(-1px, -2px) rotate(-1deg);
    }
    2% {
      transform : translate(-3px, 0px) rotate(1deg);
    }
    3% {
      transform : translate(3px, 2px) rotate(0deg);
    }
    4% {
      transform : translate(1px, -1px) rotate(1deg);
    }
    5% {
      transform : translate(-1px, 2px) rotate(-1deg);
    }
    6% {
      transform : translate(-3px, 1px) rotate(0deg);
    }
    7% {
      transform : translate(3px, 1px) rotate(-1deg);
    }
    8% {
      transform : translate(-1px, -1px) rotate(1deg);
    }
    9% {
      transform : translate(1px, 2px) rotate(0deg);
    }
    10% {
      transform : translate(1px, -2px) rotate(-1deg);
    }
  }

  ///////////////////////////////////////////// Pulse /////////////////////////////////////////////

  .pulse {
    animation        : scaleIn 3s infinite cubic-bezier(.36, .11, .89, .32);
    background-color : currentColor;
    border-radius    : 50%;
    height           : 5px;
    left             : -2.5px;
    opacity          : 0;
    position         : absolute;
    top              : -2.5px;
    width            : 5px;
  }

  @keyframes scaleIn {
    from {
      transform : scale(.5, .5);
      opacity   : .3;
    }
    to {
      transform : scale(25, 25);
      opacity   : 0;
    }
  }

  ///////////////////////////////////////////// LDS Grid /////////////////////////////////////////////

  .lds-grid {
    display  : inline-block;
    position : relative;
    width    : 80px;
    height   : 80px;
  }

  .lds-grid div {
    position      : absolute;
    width         : 16px;
    height        : 16px;
    border-radius : 50%;
    background    : map-get($accent, default);
    animation     : lds-grid 1.2s linear infinite;
  }

  .lds-grid div:nth-child(1) {
    top             : 8px;
    left            : 8px;
    animation-delay : 0s;
  }

  .lds-grid div:nth-child(2) {
    top             : 8px;
    left            : 32px;
    animation-delay : -0.4s;
  }

  .lds-grid div:nth-child(3) {
    top             : 8px;
    left            : 56px;
    animation-delay : -0.8s;
  }

  .lds-grid div:nth-child(4) {
    top             : 32px;
    left            : 8px;
    animation-delay : -0.4s;
  }

  .lds-grid div:nth-child(5) {
    top             : 32px;
    left            : 32px;
    animation-delay : -0.8s;
  }

  .lds-grid div:nth-child(6) {
    top             : 32px;
    left            : 56px;
    animation-delay : -1.2s;
  }

  .lds-grid div:nth-child(7) {
    top             : 56px;
    left            : 8px;
    animation-delay : -0.8s;
  }

  .lds-grid div:nth-child(8) {
    top             : 56px;
    left            : 32px;
    animation-delay : -1.2s;
  }

  .lds-grid div:nth-child(9) {
    top             : 56px;
    left            : 56px;
    animation-delay : -1.6s;
  }

  @keyframes lds-grid {
    0%, 100% {
      opacity : 1;
    }
    50% {
      opacity : 0.5;
    }
  }

  ///////////////////////////////////////////// Blinking /////////////////////////////////////////////

  .blinking {
    animation : blinkingText 1s infinite;
  }

  @keyframes blinkingText {
    0% {
      color : map-get($accent, default);
    }
    49% {
      color : rgba(map-get($accent, default), 0.15);
    }
    50% {
      color : rgba(map-get($accent, default), 0.15);
    }
    99% {
      color : rgba(map-get($accent, default), 0.15);
    }
    100% {
      color : map-get($accent, default);
    }
  }
}
