@use "@sharks/scss/sharks" as sharks-scss-sharks;

@mixin map-theme($theme) {

  $background : map-get($theme, background);

  .mapboxgl-popup-content {
    background : map-get($background, card);
  }

  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color : map-get($background, card);
  }

  .mapboxgl-popup-anchor-top .mapboxgl-popup-tip, .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip, .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color : map-get($background, card);
  }

  .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color : map-get($background, card);
  }

  .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color : map-get($background, card);
  }
}
