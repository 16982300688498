@mixin sharks-sidebar-theme($theme) {

  $background : map-get($theme, background);

  sharks-sidebar {
    background : map-get($background, background);
  }

  .sharks-sidebar-overlay {
    background-color : rgba(0, 0, 0, 0.6);

    &.sharks-sidebar-overlay-invisible {
      background-color : transparent;
    }
  }
}
