@use "@sharks/scss/sharks" as sharks-scss-sharks;

.confirm-dialog {

  button {
    border-radius : 16px;
  }

  --mat-dialog-actions-alignment: center;
}
