// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@mixin typography-theme($theme) {

  $background : map-get($theme, background);
  $accent     : map-get($theme, accent);
  $warn       : map-get($theme, warn);
  $is-dark    : map-get($theme, is-dark);

  a {
    color : map-get($accent, default);
  }

  code {

    &:not(.highlight) {
      background-color : map-get($background, app-bar);
    }
  }

  .changelog {

    .entry {
      background-color : map-get($background, card);
    }
  }

  .text-boxed {
    @if ($is-dark) {
      background-color : rgba(255, 255, 255, 0.12);
      color            : rgba(255, 255, 255, 0.54);
    } @else {
      background-color : rgba(0, 0, 0, 0.12);
      color            : rgba(0, 0, 0, 0.54);
    }
  }

  .strike {
    position : relative;
  }
  .strike:before {
    content       : " ";
    position      : absolute;
    top           : 50%;
    left          : 0;
    border-bottom : 1px solid map-get($warn, default);
    width         : 100%;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------
html {
  font-size      : 62.5%;
  letter-spacing : -0.1px !important;
  line-height    : 1.4 !important;
}

body {
  font-size : 14px;
}

html, body {
  -webkit-font-smoothing  : auto;
  -moz-osx-font-smoothing : auto;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight : normal;
}

h1, .h1 {
  font-size : 24px;
}

h2, .h2 {
  font-size : 20px;
}

h3, .h3 {
  font-size : 16px;
}

h4, .h4 {
  font-size : 15px;
}

h5, .h5 {
  font-size : 13px;
}

h6, .h6 {
  font-size : 12px;
}

a {
  text-decoration : none;

  &:not(.mat-mdc-button):not(.mat-mdc-raised-button):not(.mat-mdc-unelevated-button):not(.mat-mdc-outlined-button):not(.mat-mdc-icon-button):not(.mat-mdc-fab):not(.mat-mdc-mini-fab) {

    &:hover, &:active {
      text-decoration : underline;
    }
  }
}

abbr {
  border-bottom : 1px dotted rgba(0, 0, 0, 0.54);
  cursor        : help;
}

blockquote {
  border-left  : 3px solid rgba(0, 0, 0, 0.12);
  font-style   : italic;
  margin       : 1em 0;
  padding-left : 16px;

  footer {
    font-style : normal;

    &:before {
      content : '\2014 \00A0';
    }
  }

  &.reverse {
    border-left   : none;
    border-right  : 3px solid rgba(0, 0, 0, 0.12);
    padding-left  : 0;
    padding-right : 16px;
    text-align    : right;

    footer {

      &:before {
        content : '';
      }

      &:after {
        content : '\2014 \00A0';
      }
    }
  }
}

code {

  &:not(.highlight) {
    border-radius : 2px;
    color         : #106CC8;
    line-height   : 1.7;
    padding       : 2px 3px;
  }
}

dl {

  dt {
    font-weight : bold;
  }

  dd {
    margin : 4px 0 16px 0;
  }
}

mark {
  background : #F7F49A;
}

pre {
  line-height : 1.6;
  margin      : 8px 16px;
  white-space : pre-wrap;
}

small {
  font-size : 80%;
}

table {

  thead {

    tr {

      th {
        text-align : left;
      }
    }
  }
}

.text-italic {
  font-style : italic;
}

.text-semibold {
  font-weight : 600;
}

.text-bold, strong {
  font-weight : 700;
}

.text-bold-800 {
  font-weight : 800;
}

.text-bold-900 {
  font-weight : 900;
}

.text-strike {
  text-decoration : line-through;
}

.text-super {
  vertical-align : super;
}

.text-sub {
  vertical-align : sub;
}

.text-capitalize {
  text-transform : capitalize;
}

.text-lowercase {
  text-transform : lowercase;
}

.text-uppercase {
  text-transform : uppercase;
}

.text-left {
  text-align : left !important;
}

.text-center {
  text-align : center !important;
}

.text-right {
  text-align : right !important;
}

@for $weight from 1 through 9 {

  .font-weight-#{$weight * 100} {
    font-weight : #{$weight * 100};
  }
}

@for $size from 1 through 60 {

  .font-size-#{$size * 2} {
    font-size : #{$size * 2}px;
  }
}

@for $lineHeight from 1 through 60 {

  .line-height-#{$lineHeight * 2} {
    line-height : #{$lineHeight * 2}px !important;
  }
}

.line-height-1 {
  line-height : 1 !important;
}

.line-height-1\.25 {
  line-height : 1.25 !important;
}

.line-height-1\.5, .line-height-1\.50 {
  line-height : 1.5 !important;
}

.line-height-1\.75 {
  line-height : 1.75 !important;
}

.letter-spacing-condensed {
  letter-spacing : -0.02em;
}

.letter-spacing-normal {
  letter-spacing : normal;
}

.letter-spacing-relaxed {
  letter-spacing : 0.02em;
}

.text-boxed {
  border-radius : 2px;
  font-size     : 11px;
  font-weight   : 600;
  margin        : 0 8px;
  padding       : 4px 8px;
  white-space   : nowrap;
}

.text-truncate {
  display       : block;
  overflow      : hidden;
  text-overflow : ellipsis;
  white-space   : nowrap;
}

.text-nowrap {
  white-space : nowrap;
}

.text-label {
  font-size : 12px;
  font-weight: 800;
  color: #4B6279;
}

.message-box {
  background  : #607D8B;
  border-left : 6px solid #37474F;
  color       : rgba(255, 255, 255, 1);
  padding     : 16px;

  &.error {
    background        : #EF5350;
    border-left-color : #B71C1C;
  }

  &.warning {
    background        : #FFECB3;
    border-left-color : #FFC107;
    color             : rgba(0, 0, 0, 0.87);
  }

  &.success {
    background        : #4CAF50;
    border-left-color : #2E7D32;
  }

  &.info {
    background        : #B3E5FC;
    border-left-color : #03A9F4;
    color             : rgba(0, 0, 0, 0.87);
  }
}

.mat-body-1.mat-body-1 {
  margin: 0;
}
