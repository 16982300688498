// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@mixin cards-theme($theme) {

  $background : map-get($theme, background);
  $foreground : map-get($theme, foreground);

  .sharks-card {
    background   : map-get($background, card);
    border-color : map-get($foreground, divider);

    .card-divider {
      border-top : 1px solid map-get($foreground, divider);
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Cards
// -----------------------------------------------------------------------------------------------------
.sharks-card {
  border        : 1px solid;
  border-radius : 8px;
  max-width     : 320px;
  min-width     : 320px;

  &.variable-width {
    min-width : 0;
  }

  &.auto-width {
    max-width : none;
    min-width : 0;
  }

  .mat-mdc-button {
    min-width : 0 !important;
    padding   : 0 8px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-toggle-group,
  .mat-button-toggle-standalone {
    box-shadow : none !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-labels {
    justify-content : center;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label {
    min-width : 0 !important;
  }

  .card-divider {
    margin : 16px;

    &.full-width {
      margin : 0;
    }
  }

  .card-expand-area {
    overflow : hidden;

    .card-expanded-content {
      line-height : 1.75;
      padding     : 8px 16px 16px 16px;
    }
  }
}
