@use '@angular/material' as mat;

@mixin file-sharing-theme($theme) {

  $background : map-get($theme, background);

  #file-sharing {

    .content {
      @include mat.elevation(0);

      .sidebar {

        .sidebar-content {

          .card {
            background : map-get($background, card);
            @include mat.elevation(0);
          }
        }
      }
    }
  }
}
