@use '@angular/material' as mat;
@use '@sharks/scss/core' as shark-scss-core;
@use '@sharks/scss/theme' as shark-scss-theme;
@use "app/main/apps/profile/tabs/about/about.theme" as about-theme;
@use "app/main/operation/file-sharing/file-sharing.theme" as file-sharing-theme;

$dark-primary-text       : rgba(black, 0.87);
$light-primary-text      : white;
$sharks-white            : (500: white, contrast: (500: $dark-primary-text));
$sharks-black            : (500: black, contrast: (500: $light-primary-text,));
$sharks-navy             : (
  50: #ECECEE,
  100: #C5C6CB,
  200: #9EA1A9,
  300: #7D818C,
  400: #5C616F,
  500: #3C4252,
  600: #353A48,
  700: #2D323E,
  800: #262933,
  900: #1E2129,
  A100: #C5C6CB,
  A200: #9EA1A9,
  A400: #5C616F,
  A700: #2D323E,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$custom_palettes         : (sharks-white: $sharks-white, sharks-black: $sharks-black, sharks-navy: $sharks-navy);

@include shark-scss-core.sharks-color-classes($custom_palettes);

$typography              : mat.define-typography-config(
  $font-family : 'Inter var, Helvetica Neue, Arial, sans-serif',
  $headline-6  : mat.define-typography-level(20px, 32px, 600),
  $subtitle-2  : mat.define-typography-level(14px, 24px, 600),
  $button      : mat.define-typography-level(14px, 14px, 600),
  $body-1      : mat.define-typography-level(16px, 1.125, 400)
);

@include mat.all-component-typographies($typography);

@mixin components-theme($theme) {
  @include about-theme.profile-theme($theme);
  @include file-sharing-theme.file-sharing-theme($theme);
}

$default-primary-palette : mat.define-palette($sharks-navy);
$default-accent-palette  : mat.define-palette(mat.$light-blue-palette, 700, 600, 800);
$default-warn-palette    : mat.define-palette(mat.$red-palette);

$theme                   : mat.define-light-theme(
    (
      color: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette,
      ),
    )
);

body.theme-default, authentication-login, app-reset-password {
  @include mat.all-component-themes($theme);
  @include shark-scss-theme.sharks-core-theme($theme);
  @include components-theme($theme);

  $palettes : (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette
  );

  @include shark-scss-core.sharks-color-classes($palettes);
}
