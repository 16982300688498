.docs {
  font-size : 16px;

  > .content {
    max-width : 980px;

    > .main-title {

      &:first-child {
        margin-top : 0;
      }
    }
  }

  .main-title {
    display    : flex;
    font-size  : 24px;
    margin-top : 72px;
  }

  .section-title {
    border-bottom : 1px solid #F44336;
    color         : #F44336;
    display       : inline-flex;
    font-size     : 18px;
    margin-top    : 24px;
  }

  ol,
  ul {
    padding-left : 24px;

    li {
      line-height   : 1.5;
      margin-bottom : 12px;
    }
  }

  p {
    line-height : 1.2;
  }
}
