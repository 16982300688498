@use "@sharks/scss/sharks" as sharks-scss-sharks;

.file-viewer-dialog {

  .mat-mdc-dialog-container {
    background-color : transparent;
    padding          : 0;
  }

  .dialog-content-wrapper {
    display        : flex;
    flex-direction : column;
    max-height     : 85vh;
  }
}
