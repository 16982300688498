@mixin fullcalendar-theme($theme) {

  $background : map-get($theme, background);
  $foreground : map-get($theme, foreground);

  .fc {

    .fc-view-harness {

      .fc-view.fc-dayGridMonth-view {

        .fc-head {

          > tr > .fc-head-container {
            border : none;

            .fc-row {

              .fc-day-header {
                border-color : map-get($foreground, divider);

                span {
                  align-items     : center;
                  display         : flex;
                  font-size       : 12px;
                  font-weight     : 500;
                  justify-content : center;
                  padding-top     : 8px;
                  text-transform  : uppercase;
                  @apply text-secondary;
                }
              }
            }
          }
        }

        .fc-body {

          > tr > .fc-widget-content {
            border : none;

            .fc-day-grid {

              .fc-week {

                .fc-bg {

                  .fc-day {
                    border-color : map-get($foreground, divider);

                    &.fc-today {
                      background : none;
                    }
                  }
                }

                .fc-content-skeleton {

                  .fc-day-top {
                    text-align : center;

                    &.fc-other-month {
                      opacity : 1;

                      .fc-day-number {
                        @apply text-hint;
                      }
                    }

                    &.fc-today {

                      .fc-day-number {
                        @apply bg-primary text-on-primary;
                      }
                    }

                    .fc-day-number {
                      align-items     : center;
                      border-radius   : 50%;
                      display         : inline-flex;
                      float           : none;
                      font-size       : 12px;
                      height          : 21px;
                      justify-content : center;
                      margin          : 4px 0;
                      width           : 22px;
                    }
                  }

                  .fc-event-container {

                    .fc-day-grid-event {
                      align-items   : center;
                      border        : none;
                      border-radius : 4px;
                      cursor        : pointer;
                      display       : flex;
                      font-size     : 12px;
                      height        : 22px;
                      margin        : 0 6px 4px 6px;
                      max-height    : 22px;
                      min-height    : 22px;
                      padding       : 0 5px;

                      @screen sm {
                        padding : 0 8px;
                      }
                    }
                  }

                  .fc-more {
                    font-size   : 12px;
                    font-weight : 500;
                    padding     : 0 3px;
                    white-space : nowrap;
                    @apply text-secondary;

                    @screen sm {
                      padding : 0 6px;
                    }
                  }
                }

                .fc-highlight-skeleton {

                  .fc-highlight {
                    opacity  : 1;
                    position : relative;
                    @apply bg-gray-100;
                  }
                }
              }
            }
          }
        }

        .fc-popover {
          @apply bg-card;

          &.fc-more-popover {
            border        : none;
            border-radius : 4px;
            @apply shadow-2xl;

            .fc-header {
              height     : 32px;
              max-height : 32px;
              min-height : 32px;
              padding    : 0 8px;
              @apply bg-hover;

              .fc-title {
                font-size : 12px;
                margin    : 0;
                padding   : 0;
              }
            }

            .fc-body {
              max-height : 160px;
              overflow   : hidden auto;

              .fc-event-container {
                padding : 8px;

                .fc-day-grid-event {
                  align-items   : center;
                  border        : none;
                  border-radius : 4px;
                  cursor        : pointer;
                  display       : flex;
                  font-size     : 12px;
                  height        : 22px;
                  line-height   : 1;
                  margin        : 0 0 6px 0;
                  max-height    : 22px;
                  min-height    : 22px;
                  padding       : 0 8px;

                  &:last-child {
                    margin-bottom : 0;
                  }
                }
              }
            }
          }
        }
      }

      .fc-view.fc-timeGridWeek-view {

        .fc-head {

          > tr > .fc-head-container {
            border : none;

            .fc-row {

              .fc-axis {
                border-color : map-get($foreground, divider);
                width        : 48px !important;
              }

              .fc-day-header {
                border-color : map-get($foreground, divider);

                span {
                  align-items     : center;
                  display         : flex;
                  flex-direction  : column;
                  justify-content : center;
                  width           : 100%;
                  @apply text-secondary;

                  &.fc-weekday {
                    font-size      : 12px;
                    font-weight    : 400;
                    letter-spacing : 0.055em;
                    padding-top    : 16px;
                    text-transform : uppercase;
                  }

                  &.fc-date {
                    font-size      : 26px;
                    font-weight    : 300;
                    padding-bottom : 12px;
                  }
                }
              }
            }
          }
        }

        .fc-body {

          > tr > .fc-widget-content {
            border : none;

            .fc-day-grid {

              .fc-row {
                min-height : 0;

                .fc-bg {

                  .fc-axis {
                    border-color : map-get($foreground, divider);
                    width        : 48px !important;
                  }

                  .fc-day {
                    border-color : map-get($foreground, divider);

                    &.fc-today {
                      background : none;
                    }
                  }
                }

                .fc-content-skeleton {
                  padding-bottom : 0;

                  .fc-axis {
                    width : 48px !important;
                  }

                  .fc-event-container {

                    .fc-day-grid-event {
                      align-items   : center;
                      border        : none;
                      border-radius : 4px;
                      cursor        : pointer;
                      display       : flex;
                      font-size     : 12px;
                      height        : 22px;
                      line-height   : 1;
                      margin        : 0 6px 6px 6px;
                      max-height    : 22px;
                      min-height    : 22px;
                      padding       : 0 8px;
                    }
                  }
                }
              }
            }

            .fc-divider {
              background : map-get($foreground, divider);
              border     : none;
            }

            .fc-time-grid {

              .fc-bg {

                .fc-axis {
                  border : none;
                  width  : 48px !important;

                  + .fc-day {
                    border : none;
                  }
                }

                .fc-day {
                  border-color : map-get($foreground, divider);

                  &.fc-today {
                    background : none;
                  }
                }
              }

              .fc-slats {

                .fc-axis {
                  height     : 48px;
                  text-align : center;
                  width      : 48px !important;

                  span {
                    font-size : 12px;
                    min-width : 48px;
                    width     : 48px;
                  }
                }

                .fc-time {
                  border-color : map-get($foreground, divider);
                }

                .fc-widget-content {
                  border-color : map-get($foreground, divider);
                }
              }

              .fc-content-skeleton {

                .fc-axis {
                  width : 48px !important;
                }

                .fc-event-container {
                  margin : 0 12px 0 0;

                  .fc-time-grid-event {
                    border        : none;
                    border-radius : 4px;
                    cursor        : pointer;
                    display       : flex;
                    padding       : 8px;

                    .fc-time,
                    .fc-title {
                      font-size : 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .fc-view.fc-timeGridDay-view {

        .fc-head {

          > tr > .fc-head-container {
            border : none;

            .fc-row {

              .fc-axis {
                border-color : map-get($foreground, divider);
                width        : 48px !important;
              }

              .fc-day-header {
                border-color : map-get($foreground, divider);

                span {
                  align-items     : center;
                  display         : flex;
                  flex-direction  : column;
                  justify-content : center;
                  width           : 100%;
                  @apply text-secondary;

                  &.fc-weekday {
                    font-size      : 12px;
                    font-weight    : 400;
                    letter-spacing : 0.055em;
                    padding-top    : 16px;
                    text-transform : uppercase;
                  }

                  &.fc-date {
                    font-size      : 26px;
                    font-weight    : 300;
                    padding-bottom : 12px;
                  }
                }
              }
            }
          }
        }

        .fc-body {

          > tr > .fc-widget-content {
            border : none;

            .fc-day-grid {

              .fc-bg {

                .fc-axis {
                  border-color : map-get($foreground, divider);
                }

                .fc-day {
                  border-color : map-get($foreground, divider);

                  &.fc-today {
                    background : none;
                  }
                }
              }

              .fc-row {
                min-height : 0;

                .fc-bg {

                  .fc-axis {
                    width : 48px !important;
                  }
                }

                .fc-content-skeleton {
                  padding-bottom : 0;

                  .fc-axis {
                    width : 48px !important;
                  }

                  .fc-event-container {

                    .fc-day-grid-event {
                      align-items   : center;
                      border        : none;
                      border-radius : 4px;
                      cursor        : pointer;
                      display       : flex;
                      font-size     : 12px;
                      height        : 22px;
                      line-height   : 1;
                      margin        : 0 6px 6px 6px;
                      max-height    : 22px;
                      min-height    : 22px;
                      padding       : 0 8px;
                    }
                  }
                }
              }
            }

            .fc-divider {
              border       : none;
              border-color : map-get($foreground, divider);
            }

            .fc-time-grid {

              .fc-bg {

                .fc-day {
                  border-color : map-get($foreground, divider);

                  &.fc-today {
                    background : none;
                  }
                }

                .fc-axis {
                  border : none;
                  width  : 48px !important;

                  + .fc-day {
                    border : none;
                  }
                }
              }

              .fc-slats {

                .fc-axis {
                  height     : 48px;
                  text-align : center;
                  width      : 48px !important;

                  span {
                    font-size : 12px;
                    min-width : 48px;
                    width     : 48px;
                  }
                }

                .fc-time {
                  border-color : map-get($foreground, divider);
                }

                .fc-widget-content {
                  border-color : map-get($foreground, divider);
                }
              }

              .fc-content-skeleton {

                .fc-axis {
                  width : 48px !important;
                }

                .fc-event-container {
                  margin : 0 12px 0 0;

                  .fc-time-grid-event {
                    border        : none;
                    border-radius : 4px;
                    cursor        : pointer;
                    display       : flex;
                    padding       : 8px;

                    .fc-time,
                    .fc-title {
                      font-size : 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .fc-view.fc-listYear-view {
        border : none;

        .fc-list-table {

          //Angular 17
          .fc-list-event {
            .fc-list-event-title {
              flex: 1;
            }
          }

          .fc-list-heading {
            display : none;
          }

          .fc-list-item {
            cursor  : pointer;
            display : flex;

            &:hover {

              td {
                @apply bg-hover;
              }
            }

            td {
              align-items  : center;
              border-color : map-get($foreground, divider);
              border-width : 0 0 1px 0;
              display      : flex;
              height       : 48px;
              min-height   : 48px;
              padding      : 0 8px;
              width        : auto;

              &.fc-list-item-date {
                max-width    : 100px;
                min-width    : 100px;
                order        : 1;
                padding-left : 16px;
                width        : 100px;

                @screen sm {
                  width     : 120px;
                  min-width : 120px;
                  max-width : 120px;
                }

                > span {
                  align-items : baseline;
                  display     : flex;

                  span {

                    &:first-child {
                      display         : flex;
                      font-size       : 18px;
                      justify-content : center;
                      max-width       : 32px;
                      min-width       : 32px;
                      padding-right   : 2px;
                      width           : 32px;

                      @screen sm {
                        padding-right : 8px;
                      }

                      + span {
                        display        : flex;
                        font-size      : 11px;
                        font-weight    : 500;
                        letter-spacing : 0.055em;
                        text-transform : uppercase;
                        @apply text-secondary;
                      }
                    }
                  }
                }
              }

              &.fc-list-item-time {
                flex      : 0 0 auto;
                max-width : 120px;
                min-width : 120px;
                order     : 3;
                width     : 120px;

                @screen sm {
                  width     : 160px;
                  min-width : 160px;
                  max-width : 160px;
                }
              }

              &.fc-list-item-marker {
                flex  : 0 0 auto;
                order : 2;

                .fc-event-dot {
                  border-radius : 50%;
                  height        : 12px;
                  width         : 12px;
                }
              }

              &.fc-list-item-title {
                flex          : 1 1 auto;
                font-weight   : 500;
                order         : 4;
                padding-right : 24px;
              }
            }
          }
        }
      }
    }

    .fc-day-grid-event {

      &.fc-dragging,
      &.fc-resizing {
        align-items   : center;
        border        : none;
        border-radius : 4px;
        display       : flex;
        font-size     : 12px;
        height        : 22px;
        line-height   : 1;
        margin        : 0 6px 4px 6px;
        max-height    : 22px;
        min-height    : 22px;
        padding       : 0 8px;
      }
    }
    .fc-daygrid-dot-event {
      padding: 2px 5px;
    }
    .fc-daygrid-event-dot {
      display: none;
    }
  }
}
