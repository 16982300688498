@charset "UTF-8";

@font-face {
  font-family : "sharksicon";
  src         : url("fonts/sharksicon.eot");
  src         : url("fonts/sharksicon.eot?#iefix") format("embedded-opentype"),
  url("fonts/sharksicon.woff") format("woff"),
  url("fonts/sharksicon.ttf") format("truetype"),
  url("fonts/sharksicon.svg#sharksicon") format("svg");
  font-weight : normal;
  font-style  : normal;
  
}

[data-icon]:before {
  content                 : attr(data-icon);
  font-family             : "sharksicon" !important;
  -webkit-font-smoothing  : antialiased;
  font-style              : normal !important;
  font-variant            : normal !important;
  font-weight             : normal !important;
  line-height             : 1;
  -moz-osx-font-smoothing : grayscale;
  speak                   : none;
  text-transform          : none !important;
}

[class^="sk-"]:before,
[class*=" sk-"]:before {
  font-family             : "sharksicon" !important;
  -webkit-font-smoothing  : antialiased;
  font-style              : normal !important;
  font-variant            : normal !important;
  font-weight             : normal !important;
  line-height             : 1;
  -moz-osx-font-smoothing : grayscale;
  speak                   : none;
  text-transform          : none !important;
}

.sk-abacus:before {
  content : "\61";
}

.sk-accessible:before {
  content : "\62";
}

.sk-acknowledge:before {
  content : "\63";
}

.sk-add:before {
  content : "\64";
}

.sk-address-book:before {
  content : "\65";
}

.sk-adidas:before {
  content : "\66";
}

.sk-adobe:before {
  content : "\67";
}

.sk-agreement:before {
  content : "\68";
}

.sk-airplane:before {
  content : "\69";
}

.sk-airport:before {
  content : "\6a";
}

.sk-alarm-clock:before {
  content : "\6b";
}

.sk-album:before {
  content : "\6c";
}

.sk-align-center:before {
  content : "\6d";
}

.sk-align-left:before {
  content : "\6e";
}

.sk-align-right:before {
  content : "\6f";
}

.sk-ambulance:before {
  content : "\70";
}

.sk-anchor:before {
  content : "\71";
}

.sk-android:before {
  content : "\72";
}

.sk-anvil:before {
  content : "\73";
}

.sk-apple-logo:before {
  content : "\74";
}

.sk-archive:before {
  content : "\75";
}

.sk-arrow-down:before {
  content : "\76";
}

.sk-arrow-left:before {
  content : "\77";
}

.sk-arrow-right:before {
  content : "\78";
}

.sk-arrow-up:before {
  content : "\79";
}

.sk-ascending:before {
  content : "\7a";
}

.sk-assign:before {
  content : "\41";
}

.sk-atom:before {
  content : "\42";
}

.sk-award:before {
  content : "\43";
}

.sk-baby:before {
  content : "\44";
}

.sk-backpack:before {
  content : "\45";
}

.sk-backward:before {
  content : "\46";
}

.sk-bacterial:before {
  content : "\47";
}

.sk-badge:before {
  content : "\48";
}

.sk-balance-scale:before {
  content : "\49";
}

.sk-bandage:before {
  content : "\4a";
}

.sk-bank:before {
  content : "\4b";
}

.sk-bar-chart:before {
  content : "\4c";
}

.sk-barcode:before {
  content : "\4d";
}

.sk-barcode-scanner:before {
  content : "\4e";
}

.sk-barrier:before {
  content : "\4f";
}

.sk-battery:before {
  content : "\50";
}

.sk-battery-charging:before {
  content : "\51";
}

.sk-beaker:before {
  content : "\52";
}

.sk-bell:before {
  content : "\53";
}

.sk-bell-ring:before {
  content : "\54";
}

.sk-bell-silence:before {
  content : "\55";
}

.sk-bike:before {
  content : "\56";
}

.sk-bill:before {
  content : "\57";
}

.sk-billboard:before {
  content : "\58";
}

.sk-binoculars:before {
  content : "\59";
}

.sk-biohazard:before {
  content : "\5a";
}

.sk-bitcoin:before {
  content : "\30";
}

.sk-blackboard:before {
  content : "\31";
}

.sk-block:before {
  content : "\32";
}

.sk-blood-drop:before {
  content : "\33";
}

.sk-bluetooth:before {
  content : "\34";
}

.sk-bold:before {
  content : "\35";
}

.sk-bolt:before {
  content : "\36";
}

.sk-book:before {
  content : "\37";
}

.sk-book-mark:before {
  content : "\38";
}

.sk-border:before {
  content : "\39";
}

.sk-border-all:before {
  content : "\21";
}

.sk-border-bottom:before {
  content : "\22";
}

.sk-border-divider:before {
  content : "\23";
}

.sk-border-left:before {
  content : "\24";
}

.sk-border-none:before {
  content : "\25";
}

.sk-border-right:before {
  content : "\26";
}

.sk-border-top:before {
  content : "\27";
}

.sk-boxes:before {
  content : "\28";
}

.sk-brain:before {
  content : "\29";
}

.sk-brick-wall:before {
  content : "\2a";
}

.sk-bucket:before {
  content : "\2b";
}

.sk-bug:before {
  content : "\2c";
}

.sk-bulldozer:before {
  content : "\2d";
}

.sk-bullhorn:before {
  content : "\2e";
}

.sk-bunker:before {
  content : "\2f";
}

.sk-burner:before {
  content : "\3a";
}

.sk-bus:before {
  content : "\3b";
}

.sk-button-pause:before {
  content : "\3c";
}

.sk-button-play:before {
  content : "\3d";
}

.sk-button-stop:before {
  content : "\3e";
}

.sk-cabin:before {
  content : "\3f";
}

.sk-calculator:before {
  content : "\40";
}

.sk-calendar:before {
  content : "\5b";
}

.sk-calendar-empty:before {
  content : "\5d";
}

.sk-calendar-time:before {
  content : "\5e";
}

.sk-calendar-today:before {
  content : "\5f";
}

.sk-camera-drone:before {
  content : "\60";
}

.sk-candidate:before {
  content : "\7b";
}

.sk-car:before {
  content : "\7c";
}

.sk-car-battery:before {
  content : "\7d";
}

.sk-car-crash:before {
  content : "\7e";
}

.sk-caravan:before {
  content : "\5c";
}

.sk-card:before {
  content : "\e000";
}

.sk-cargo:before {
  content : "\e001";
}

.sk-cart:before {
  content : "\e002";
}

.sk-cash:before {
  content : "\e003";
}

.sk-cash-register:before {
  content : "\e004";
}

.sk-cassette:before {
  content : "\e005";
}

.sk-caulk-gun:before {
  content : "\e006";
}

.sk-cctv:before {
  content : "\e007";
}

.sk-cement-truck:before {
  content : "\e008";
}

.sk-certificate:before {
  content : "\e009";
}

.sk-chainsaw:before {
  content : "\e00a";
}

.sk-charging-station:before {
  content : "\e00b";
}

.sk-check:before {
  content : "\e00c";
}

.sk-check-thick:before {
  content : "\e00d";
}

.sk-check-thin:before {
  content : "\e00e";
}

.sk-cheque:before {
  content : "\e00f";
}

.sk-chevron-down:before {
  content : "\e010";
}

.sk-chevron-left:before {
  content : "\e011";
}

.sk-chevron-right:before {
  content : "\e012";
}

.sk-chevron-up:before {
  content : "\e013";
}

.sk-chimney:before {
  content : "\e014";
}

.sk-chrome:before {
  content : "\e015";
}

.sk-church:before {
  content : "\e016";
}

.sk-circular-saw:before {
  content : "\e017";
}

.sk-claim:before {
  content : "\e018";
}

.sk-clipboard:before {
  content : "\e019";
}

.sk-clock:before {
  content : "\e01a";
}

.sk-close:before {
  content : "\e01b";
}

.sk-close-thick:before {
  content : "\e01c";
}

.sk-close-thin:before {
  content : "\e01d";
}

.sk-cloud:before {
  content : "\e01e";
}

.sk-comedy:before {
  content : "\e01f";
}

.sk-comment:before {
  content : "\e020";
}

.sk-compact-disc:before {
  content : "\e021";
}

.sk-company:before {
  content : "\e022";
}

.sk-compass:before {
  content : "\e023";
}

.sk-completed:before {
  content : "\e024";
}

.sk-concierge-bell:before {
  content : "\e025";
}

.sk-cone:before {
  content : "\e026";
}

.sk-contrast:before {
  content : "\e027";
}

.sk-conversation:before {
  content : "\e028";
}

.sk-copy:before {
  content : "\e029";
}

.sk-cover-page:before {
  content : "\e02a";
}

.sk-craft:before {
  content : "\e02b";
}

.sk-crane:before {
  content : "\e02c";
}

.sk-crane-truck:before {
  content : "\e02d";
}

.sk-credit-card:before {
  content : "\e02e";
}

.sk-crew-exchange:before {
  content : "\e02f";
}

.sk-cruise:before {
  content : "\e030";
}

.sk-cut:before {
  content : "\e031";
}

.sk-cutter:before {
  content : "\e032";
}

.sk-database:before {
  content : "\e033";
}

.sk-debate:before {
  content : "\e034";
}

.sk-decrease:before {
  content : "\e035";
}

.sk-delivery:before {
  content : "\e036";
}

.sk-delivery-truck:before {
  content : "\e037";
}

.sk-demolition:before {
  content : "\e038";
}

.sk-denied:before {
  content : "\e039";
}

.sk-derrick:before {
  content : "\e03a";
}

.sk-descending:before {
  content : "\e03b";
}

.sk-desktop:before {
  content : "\e03c";
}

.sk-deslop:before {
  content : "\e03d";
}

.sk-diploma:before {
  content : "\e03e";
}

.sk-dna:before {
  content : "\e03f";
}

.sk-dollar:before {
  content : "\e040";
}

.sk-doorknob:before {
  content : "\e041";
}

.sk-double-chevron-down:before {
  content : "\e042";
}

.sk-double-chevron-left:before {
  content : "\e043";
}

.sk-double-chevron-right:before {
  content : "\e044";
}

.sk-double-chevron-up:before {
  content : "\e045";
}

.sk-double-decker-bus:before {
  content : "\e046";
}

.sk-download:before {
  content : "\e047";
}

.sk-drafting-compass:before {
  content : "\e048";
}

.sk-driller:before {
  content : "\e049";
}

.sk-drilling:before {
  content : "\e04a";
}

.sk-drone:before {
  content : "\e04b";
}

.sk-dropper:before {
  content : "\e04c";
}

.sk-dump-truck:before {
  content : "\e04d";
}

.sk-dumpster:before {
  content : "\e04e";
}

.sk-ea-sports:before {
  content : "\e04f";
}

.sk-earphone:before {
  content : "\e050";
}

.sk-eclipse:before {
  content : "\e051";
}

.sk-electric-car:before {
  content : "\e052";
}

.sk-electrical-bus:before {
  content : "\e053";
}

.sk-electricity:before {
  content : "\e054";
}

.sk-email:before {
  content : "\e055";
}

.sk-email-open:before {
  content : "\e056";
}

.sk-email-send:before {
  content : "\e057";
}

.sk-enter:before {
  content : "\e058";
}

.sk-erase:before {
  content : "\e059";
}

.sk-excavator:before {
  content : "\e05a";
}

.sk-excel:before {
  content : "\e05b";
}

.sk-exchange:before {
  content : "\e05c";
}

.sk-exclamation:before {
  content : "\e05d";
}

.sk-exit:before {
  content : "\e05e";
}

.sk-eye:before {
  content : "\e05f";
}

.sk-eye-slash:before {
  content : "\e060";
}

.sk-facebook:before {
  content : "\e061";
}

.sk-family:before {
  content : "\e062";
}

.sk-fast-backward:before {
  content : "\e063";
}

.sk-fast-forward:before {
  content : "\e064";
}

.sk-feedback:before {
  content : "\e065";
}

.sk-female:before {
  content : "\e066";
}

.sk-female-gender:before {
  content : "\e067";
}

.sk-file:before {
  content : "\e068";
}

.sk-file-and-folder:before {
  content : "\e069";
}

.sk-film-strip:before {
  content : "\e06a";
}

.sk-filter:before {
  content : "\e06b";
}

.sk-fingerprint:before {
  content : "\e06c";
}

.sk-fire:before {
  content : "\e06d";
}

.sk-fire-extinguisher:before {
  content : "\e06e";
}

.sk-fire-hydrant:before {
  content : "\e06f";
}

.sk-fire-truck:before {
  content : "\e070";
}

.sk-fireplace:before {
  content : "\e071";
}

.sk-first-aid:before {
  content : "\e072";
}

.sk-flag:before {
  content : "\e073";
}

.sk-flashlight:before {
  content : "\e074";
}

.sk-flask:before {
  content : "\e075";
}

.sk-flotsam:before {
  content : "\e076";
}

.sk-flying-car:before {
  content : "\e077";
}

.sk-folder:before {
  content : "\e078";
}

.sk-folder-open:before {
  content : "\e079";
}

.sk-font:before {
  content : "\e07a";
}

.sk-font-decrease:before {
  content : "\e07b";
}

.sk-font-increase:before {
  content : "\e07c";
}

.sk-forklift:before {
  content : "\e07d";
}

.sk-form:before {
  content : "\e07e";
}

.sk-forward:before {
  content : "\e07f";
}

.sk-free:before {
  content : "\e080";
}

.sk-garbage:before {
  content : "\e081";
}

.sk-garbage-truck:before {
  content : "\e082";
}

.sk-gas-station:before {
  content : "\e083";
}

.sk-gear:before {
  content : "\e084";
}

.sk-gem:before {
  content : "\e085";
}

.sk-gift:before {
  content : "\e086";
}

.sk-gmail:before {
  content : "\e087";
}

.sk-golf-cart:before {
  content : "\e088";
}

.sk-google:before {
  content : "\e089";
}

.sk-google-drive:before {
  content : "\e08a";
}

.sk-google-translate:before {
  content : "\e08b";
}

.sk-gown:before {
  content : "\e08c";
}

.sk-graduation-hat:before {
  content : "\e08d";
}

.sk-gramophone:before {
  content : "\e08e";
}

.sk-grid:before {
  content : "\e08f";
}

.sk-gst:before {
  content : "\e090";
}

.sk-hail:before {
  content : "\e091";
}

.sk-hammer:before {
  content : "\e092";
}

.sk-handsaw:before {
  content : "\e093";
}

.sk-hard-disk:before {
  content : "\e094";
}

.sk-hard-hat:before {
  content : "\e095";
}

.sk-headset:before {
  content : "\e096";
}

.sk-heart:before {
  content : "\e097";
}

.sk-heartbeat:before {
  content : "\e098";
}

.sk-helicopter:before {
  content : "\e099";
}

.sk-helm:before {
  content : "\e09a";
}

.sk-high-speed-train:before {
  content : "\e09b";
}

.sk-highlight:before {
  content : "\e09c";
}

.sk-history:before {
  content : "\e09d";
}

.sk-hospital:before {
  content : "\e09e";
}

.sk-hotel:before {
  content : "\e09f";
}

.sk-hourglass:before {
  content : "\e0a0";
}

.sk-hurricane:before {
  content : "\e0a1";
}

.sk-icicles:before {
  content : "\e0a2";
}

.sk-id-card:before {
  content : "\e0a3";
}

.sk-igloo:before {
  content : "\e0a4";
}

.sk-image:before {
  content : "\e0a5";
}

.sk-immigration:before {
  content : "\e0a6";
}

.sk-increase:before {
  content : "\e0a7";
}

.sk-industrial-robot:before {
  content : "\e0a8";
}

.sk-insert-first-column:before {
  content : "\e0a9";
}

.sk-insert-first-row:before {
  content : "\e0aa";
}

.sk-insert-last-column:before {
  content : "\e0ab";
}

.sk-insert-last-row:before {
  content : "\e0ac";
}

.sk-instagram:before {
  content : "\e0ad";
}

.sk-invoice:before {
  content : "\e0ae";
}

.sk-italic:before {
  content : "\e0af";
}

.sk-itinerary:before {
  content : "\e0b0";
}

.sk-jet-pack:before {
  content : "\e0b1";
}

.sk-jetty:before {
  content : "\e0b2";
}

.sk-key:before {
  content : "\e0b3";
}

.sk-keyboard:before {
  content : "\e0b4";
}

.sk-ladder:before {
  content : "\e0b5";
}

.sk-laptop:before {
  content : "\e0b6";
}

.sk-laser-cutter:before {
  content : "\e0b7";
}

.sk-layers:before {
  content : "\e0b8";
}

.sk-layout:before {
  content : "\e0b9";
}

.sk-leadership:before {
  content : "\e0ba";
}

.sk-lectern:before {
  content : "\e0bb";
}

.sk-lecture:before {
  content : "\e0bc";
}

.sk-level-crossing:before {
  content : "\e0bd";
}

.sk-library:before {
  content : "\e0be";
}

.sk-line-chart:before {
  content : "\e0bf";
}

.sk-line-down:before {
  content : "\e0c0";
}

.sk-line-left:before {
  content : "\e0c1";
}

.sk-line-right:before {
  content : "\e0c2";
}

.sk-line-up:before {
  content : "\e0c3";
}

.sk-live-news:before {
  content : "\e0c4";
}

.sk-location:before {
  content : "\e0c5";
}

.sk-lock:before {
  content : "\e0c6";
}

.sk-luggage:before {
  content : "\e0c7";
}

.sk-luggage-cart:before {
  content : "\e0c8";
}

.sk-lungs:before {
  content : "\e0c9";
}

.sk-magnet:before {
  content : "\e0ca";
}

.sk-male:before {
  content : "\e0cb";
}

.sk-male-gender:before {
  content : "\e0cc";
}

.sk-map:before {
  content : "\e0cd";
}

.sk-market:before {
  content : "\e0ce";
}

.sk-mastercard:before {
  content : "\e0cf";
}

.sk-measuring-tape:before {
  content : "\e0d0";
}

.sk-medal:before {
  content : "\e0d1";
}

.sk-medical-mask:before {
  content : "\e0d2";
}

.sk-menu:before {
  content : "\e0d3";
}

.sk-merge:before {
  content : "\e0d4";
}

.sk-meteor:before {
  content : "\e0d5";
}

.sk-metro:before {
  content : "\e0d6";
}

.sk-metro-door:before {
  content : "\e0d7";
}

.sk-mic:before {
  content : "\e0d8";
}

.sk-microchip:before {
  content : "\e0d9";
}

.sk-microphone:before {
  content : "\e0da";
}

.sk-microscope:before {
  content : "\e0db";
}

.sk-mobile-elevator:before {
  content : "\e0dc";
}

.sk-mobile-payment:before {
  content : "\e0dd";
}

.sk-mobile-phone:before {
  content : "\e0de";
}

.sk-mobile-rotation:before {
  content : "\e0df";
}

.sk-molecule:before {
  content : "\e0e0";
}

.sk-money-bag:before {
  content : "\e0e1";
}

.sk-moon:before {
  content : "\e0e2";
}

.sk-moon-rover:before {
  content : "\e0e3";
}

.sk-more:before {
  content : "\e0e4";
}

.sk-motorbike:before {
  content : "\e0e5";
}

.sk-mouse-wire:before {
  content : "\e0e6";
}

.sk-mouse-wireless:before {
  content : "\e0e7";
}

.sk-name-card:before {
  content : "\e0e8";
}

.sk-nav-down:before {
  content : "\e0e9";
}

.sk-nav-left:before {
  content : "\e0ea";
}

.sk-nav-right:before {
  content : "\e0eb";
}

.sk-nav-up:before {
  content : "\e0ec";
}

.sk-newspaper:before {
  content : "\e0ed";
}

.sk-nike:before {
  content : "\e0ee";
}

.sk-nintendo-switch:before {
  content : "\e0ef";
}

.sk-no-smoke:before {
  content : "\e0f0";
}

.sk-north:before {
  content : "\e0f1";
}

.sk-not-found:before {
  content : "\e0f2";
}

.sk-number-0:before {
  content : "\e0f3";
}

.sk-number-1:before {
  content : "\e0f4";
}

.sk-number-2:before {
  content : "\e0f5";
}

.sk-number-3:before {
  content : "\e0f6";
}

.sk-number-4:before {
  content : "\e0f7";
}

.sk-number-5:before {
  content : "\e0f8";
}

.sk-number-6:before {
  content : "\e0f9";
}

.sk-number-7:before {
  content : "\e0fa";
}

.sk-nut:before {
  content : "\e0fb";
}

.sk-observatory:before {
  content : "\e0fc";
}

.sk-offshore:before {
  content : "\e0fd";
}

.sk-oil-setting:before {
  content : "\e0fe";
}

.sk-otoscope:before {
  content : "\e0ff";
}

.sk-outlook:before {
  content : "\e100";
}

.sk-paint-bucket:before {
  content : "\e101";
}

.sk-painting:before {
  content : "\e102";
}

.sk-palette:before {
  content : "\e103";
}

.sk-pallet:before {
  content : "\e104";
}

.sk-paperclip:before {
  content : "\e105";
}

.sk-papyrus:before {
  content : "\e106";
}

.sk-paramedic:before {
  content : "\e107";
}

.sk-parcel:before {
  content : "\e108";
}

.sk-parcel-setting:before {
  content : "\e109";
}

.sk-passenger:before {
  content : "\e10a";
}

.sk-passport:before {
  content : "\e10b";
}

.sk-paste:before {
  content : "\e10c";
}

.sk-payment-setting:before {
  content : "\e10d";
}

.sk-paypal:before {
  content : "\e10e";
}

.sk-payroll:before {
  content : "\e10f";
}

.sk-payslip:before {
  content : "\e110";
}

.sk-pdf:before {
  content : "\e111";
}

.sk-pen:before {
  content : "\e112";
}

.sk-pendulum:before {
  content : "\e113";
}

.sk-perfume:before {
  content : "\e114";
}

.sk-periodic-table:before {
  content : "\e115";
}

.sk-pestle:before {
  content : "\e116";
}

.sk-petroleum:before {
  content : "\e117";
}

.sk-ph-meter:before {
  content : "\e118";
}

.sk-phone:before {
  content : "\e119";
}

.sk-photo-camera:before {
  content : "\e11a";
}

.sk-pickaxe:before {
  content : "\e11b";
}

.sk-pie-chart:before {
  content : "\e11c";
}

.sk-pills:before {
  content : "\e11d";
}

.sk-pipe:before {
  content : "\e11e";
}

.sk-planning:before {
  content : "\e11f";
}

.sk-play-back:before {
  content : "\e120";
}

.sk-play-end:before {
  content : "\e121";
}

.sk-play-forward:before {
  content : "\e122";
}

.sk-play-start:before {
  content : "\e123";
}

.sk-playstore:before {
  content : "\e124";
}

.sk-pliers:before {
  content : "\e125";
}

.sk-police-car:before {
  content : "\e126";
}

.sk-polisher:before {
  content : "\e127";
}

.sk-poop:before {
  content : "\e128";
}

.sk-power-off:before {
  content : "\e129";
}

.sk-powerpoint:before {
  content : "\e12a";
}

.sk-prescription:before {
  content : "\e12b";
}

.sk-presentation:before {
  content : "\e12c";
}

.sk-print:before {
  content : "\e12d";
}

.sk-profile:before {
  content : "\e12e";
}

.sk-projector:before {
  content : "\e12f";
}

.sk-pyramid-chart:before {
  content : "\e130";
}

.sk-qr-code:before {
  content : "\e131";
}

.sk-question:before {
  content : "\e132";
}

.sk-question-and-answer:before {
  content : "\e133";
}

.sk-radiation:before {
  content : "\e134";
}

.sk-radio:before {
  content : "\e135";
}

.sk-rain:before {
  content : "\e136";
}

.sk-rainbow:before {
  content : "\e137";
}

.sk-random:before {
  content : "\e138";
}

.sk-rate-1:before {
  content : "\e139";
}

.sk-rate-2:before {
  content : "\e13a";
}

.sk-rate-3:before {
  content : "\e13b";
}

.sk-rate-4:before {
  content : "\e13c";
}

.sk-rate-5:before {
  content : "\e13d";
}

.sk-restroom:before {
  content : "\e13e";
}

.sk-robot:before {
  content : "\e13f";
}

.sk-rocket:before {
  content : "\e140";
}

.sk-rotate:before {
  content : "\e141";
}

.sk-router:before {
  content : "\e142";
}

.sk-routing:before {
  content : "\e143";
}

.sk-ruler:before {
  content : "\e144";
}

.sk-safety-vest:before {
  content : "\e145";
}

.sk-satellite:before {
  content : "\e146";
}

.sk-satellite-dish:before {
  content : "\e147";
}

.sk-save:before {
  content : "\e148";
}

.sk-scanner:before {
  content : "\e149";
}

.sk-scooter:before {
  content : "\e14a";
}

.sk-screw:before {
  content : "\e14b";
}

.sk-screwdriver:before {
  content : "\e14c";
}

.sk-sea:before {
  content : "\e14d";
}

.sk-search:before {
  content : "\e14e";
}

.sk-sewing-machine:before {
  content : "\e14f";
}

.sk-shears:before {
  content : "\e150";
}

.sk-shield:before {
  content : "\e151";
}

.sk-shopping-basket:before {
  content : "\e152";
}

.sk-shovel:before {
  content : "\e153";
}

.sk-signal-monitor:before {
  content : "\e154";
}

.sk-signpost:before {
  content : "\e155";
}

.sk-sim-card:before {
  content : "\e156";
}

.sk-singapore-msp:before {
  content : "\e157";
}

.sk-singapore-ppft:before {
  content : "\e158";
}

.sk-singapore-wcp:before {
  content : "\e159";
}

.sk-smart-watch:before {
  content : "\e15a";
}

.sk-smoke:before {
  content : "\e15b";
}

.sk-snowflakes:before {
  content : "\e15c";
}

.sk-snowmobile:before {
  content : "\e15d";
}

.sk-snowplow:before {
  content : "\e15e";
}

.sk-social-network:before {
  content : "\e15f";
}

.sk-solar-panels:before {
  content : "\e160";
}

.sk-sound-mixer:before {
  content : "\e161";
}

.sk-speaker:before {
  content : "\e162";
}

.sk-speech:before {
  content : "\e163";
}

.sk-speed:before {
  content : "\e164";
}

.sk-split:before {
  content : "\e165";
}

.sk-stamp:before {
  content : "\e166";
}

.sk-star:before {
  content : "\e167";
}

.sk-star-0:before {
  content : "\e168";
}

.sk-star-1:before {
  content : "\e169";
}

.sk-star-2:before {
  content : "\e16a";
}

.sk-star-3:before {
  content : "\e16b";
}

.sk-star-4:before {
  content : "\e16c";
}

.sk-star-5:before {
  content : "\e16d";
}

.sk-star-five:before {
  content : "\e16e";
}

.sk-star-four:before {
  content : "\e16f";
}

.sk-star-one:before {
  content : "\e170";
}

.sk-star-three:before {
  content : "\e171";
}

.sk-star-two:before {
  content : "\e172";
}

.sk-stationery:before {
  content : "\e173";
}

.sk-steamroller:before {
  content : "\e174";
}

.sk-stethoscope:before {
  content : "\e175";
}

.sk-sticky-note:before {
  content : "\e176";
}

.sk-stop:before {
  content : "\e177";
}

.sk-stopwatch:before {
  content : "\e178";
}

.sk-store-close:before {
  content : "\e179";
}

.sk-store-open:before {
  content : "\e17a";
}

.sk-stretcher:before {
  content : "\e17b";
}

.sk-success:before {
  content : "\e17c";
}

.sk-suitcase:before {
  content : "\e17d";
}

.sk-sun:before {
  content : "\e17e";
}

.sk-sunbed:before {
  content : "\e17f";
}

.sk-sunset:before {
  content : "\e180";
}

.sk-survey:before {
  content : "\e181";
}

.sk-sync:before {
  content : "\e182";
}

.sk-syringe:before {
  content : "\e183";
}

.sk-tablet:before {
  content : "\e184";
}

.sk-tag:before {
  content : "\e185";
}

.sk-tanker-truck:before {
  content : "\e186";
}

.sk-tax:before {
  content : "\e187";
}

.sk-taxi:before {
  content : "\e188";
}

.sk-team:before {
  content : "\e189";
}

.sk-telescope:before {
  content : "\e18a";
}

.sk-tent:before {
  content : "\e18b";
}

.sk-terminal:before {
  content : "\e18c";
}

.sk-thermometer:before {
  content : "\e18d";
}

.sk-time-setting:before {
  content : "\e18e";
}

.sk-tobacco-pipe:before {
  content : "\e18f";
}

.sk-tomography:before {
  content : "\e190";
}

.sk-toolbox:before {
  content : "\e191";
}

.sk-tools:before {
  content : "\e192";
}

.sk-tooth:before {
  content : "\e193";
}

.sk-tornado:before {
  content : "\e194";
}

.sk-tracking:before {
  content : "\e195";
}

.sk-tractor:before {
  content : "\e196";
}

.sk-traffic-light:before {
  content : "\e197";
}

.sk-train:before {
  content : "\e198";
}

.sk-train-sign:before {
  content : "\e199";
}

.sk-tram:before {
  content : "\e19a";
}

.sk-trash:before {
  content : "\e19b";
}

.sk-trolley:before {
  content : "\e19c";
}

.sk-trophy:before {
  content : "\e19d";
}

.sk-trowel:before {
  content : "\e19e";
}

.sk-truck:before {
  content : "\e19f";
}

.sk-tweezers:before {
  content : "\e1a0";
}

.sk-twitter:before {
  content : "\e1a1";
}

.sk-typography:before {
  content : "\e1a2";
}

.sk-uber:before {
  content : "\e1a3";
}

.sk-ufo:before {
  content : "\e1a4";
}

.sk-unlink:before {
  content : "\e1a5";
}

.sk-unlock:before {
  content : "\e1a6";
}

.sk-unshield:before {
  content : "\e1a7";
}

.sk-upload:before {
  content : "\e1a8";
}

.sk-usb:before {
  content : "\e1a9";
}

.sk-user:before {
  content : "\e1aa";
}

.sk-user-setting:before {
  content : "\e1ab";
}

.sk-valve:before {
  content : "\e1ac";
}

.sk-van:before {
  content : "\e1ad";
}

.sk-vessel:before {
  content : "\e1ae";
}

.sk-video-camera:before {
  content : "\e1af";
}

.sk-video-player:before {
  content : "\e1b0";
}

.sk-virus:before {
  content : "\e1b1";
}

.sk-visa:before {
  content : "\e1b2";
}

.sk-volcano:before {
  content : "\e1b3";
}

.sk-voltmeter:before {
  content : "\e1b4";
}

.sk-volume-down:before {
  content : "\e1b5";
}

.sk-volume-off:before {
  content : "\e1b6";
}

.sk-volume-on:before {
  content : "\e1b7";
}

.sk-volume-up:before {
  content : "\e1b8";
}

.sk-vote:before {
  content : "\e1b9";
}

.sk-voucher:before {
  content : "\e1ba";
}

.sk-voyage:before {
  content : "\e1bb";
}

.sk-wallet:before {
  content : "\e1bc";
}

.sk-wallpaper:before {
  content : "\e1bd";
}

.sk-warehouse:before {
  content : "\e1be";
}

.sk-warning:before {
  content : "\e1bf";
}

.sk-watch:before {
  content : "\e1c0";
}

.sk-weather-map:before {
  content : "\e1c1";
}

.sk-weight:before {
  content : "\e1c2";
}

.sk-welding-machine:before {
  content : "\e1c3";
}

.sk-welding-mask:before {
  content : "\e1c4";
}

.sk-wet-floor:before {
  content : "\e1c5";
}

.sk-whatsapp:before {
  content : "\e1c6";
}

.sk-wheelbarrow:before {
  content : "\e1c7";
}

.sk-wheelchair:before {
  content : "\e1c8";
}

.sk-wifi:before {
  content : "\e1c9";
}

.sk-wii:before {
  content : "\e1ca";
}

.sk-wind:before {
  content : "\e1cb";
}

.sk-windows:before {
  content : "\e1cc";
}

.sk-wood:before {
  content : "\e1cd";
}

.sk-word:before {
  content : "\e1ce";
}

.sk-world-setting:before {
  content : "\e1cf";
}

.sk-wrench:before {
  content : "\e1d0";
}

.sk-x-ray:before {
  content : "\e1d1";
}

.sk-xbox:before {
  content : "\e1d2";
}

.sk-youtube:before {
  content : "\e1d3";
}

.sk-zone:before {
  content : "\e1d4";
}

.sk-singapore:before {
  content : "\e1d5";
}
