@use "@sharks/scss/sharks" as sharks-scss-sharks;

.file-upload-dialog {

  @include sharks-scss-sharks.media-breakpoint('xs') {
    width : 100%;
  }

  @include sharks-scss-sharks.media-breakpoint('gt-xs') {
    width : 720px;
  }

  .mat-mdc-dialog-container {
    padding : 0;

    .preview {
      background-image : none;
    }
  }

  .dialog-content-wrapper {
    display        : flex;
    flex-direction : column;
    max-height     : 85vh;
  }
}
