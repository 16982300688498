body:not(.is-mobile) {

  ::-webkit-scrollbar {
    background-color : rgba(0, 0, 0, 0);
    height           : 12px;
    width            : 12px;
  }

  ::-webkit-scrollbar:hover {
    background-color : rgba(0, 0, 0, 0.12);
  }

  ::-webkit-scrollbar-thumb {
    border        : 2px solid transparent;
    border-radius : 12px;
    box-shadow    : inset 0 0 0 12px rgba(0, 0, 0, 0.37);
  }

  ::-webkit-scrollbar-thumb:active {
    border-radius : 12px;
    box-shadow    : inset 0 0 0 12px rgba(0, 0, 0, 0.54);
  }
}
