@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900');

@import "app/app.theme";
@import "@sharks/scss/core";
@import "mapbox-gl/dist/mapbox-gl.css";
@import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";

html {
  font-family           : 'Inter var', 'Helvetica Neue', 'Arial', sans-serif;
  font-feature-settings : 'cv11';
}

input, textarea {
  font-family           : 'Inter var', 'Helvetica Neue', 'Arial', sans-serif;
  font-feature-settings : 'cv11';
}

// Prevent border overlap in grid when scrolled to the bottom of the table
mat-paginator {
  margin-top : -1px;
  z-index    : 999;
}

.mapboxgl-map {
  font-family           : 'Inter var', 'Helvetica Neue', 'Arial', sans-serif;
  font-feature-settings : 'cv11';
}

.mat-mdc-button > .mat-icon {
  margin-right : 0 !important;
}

.mat-mdc-icon-button {
  align-items     : center;
  display         : inline-flex !important;
  height          : 40px !important;
  justify-content : center;
  padding         : 0 !important;
  width           : 40px !important;

  .mat-mdc-button-touch-target {
    height : 40px !important;
    width  : 40px !important;
  }
}
