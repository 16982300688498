@mixin sharks-history-theme($theme) {

  $primary : map-get($theme, primary);

  sharks-history {

    .message-row {

      &.contact {

        .bubble {
          background-color : map-get($primary, default);
          color            : map-get($primary, default-contrast);
        }
      }

      &.me {

        .bubble {
          color            : rgba(0, 0, 0, 0.87);
          background-color : #E0E0E0;
        }
      }
    }
  }
}
