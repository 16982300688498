@use "@sharks/scss/sharks" as sharks-scss-sharks;

.email-dialog {
  width : 100%;

  .mat-mdc-dialog-container {
    padding : 0;

    .compose-form {

      .show-hide-extra-fields {
        cursor     : pointer;
        font-size  : 12px;
        text-align : right;
      }

      .attachment-list {
        font-size : 13px;

        .attachment {
          border        : 1px solid lightgray;
          border-radius : 8px;
          margin-top    : 8px;
          padding-left  : 16px;

          .filename {
            font-weight : 600;
          }

          &:last-child {
            margin-bottom : 0;
          }
        }
      }
    }
  }

  .dialog-content-wrapper {
    display        : flex;
    flex-direction : column;
    max-height     : 85vh;
  }

  .ck-content .image {
    margin : 0 !important;
  }
}
