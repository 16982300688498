@use "@sharks/scss/sharks" as sharks-scss-sharks;

.loading-dialog {

  .mat-mdc-dialog-container {
    background-color : transparent !important;
    box-shadow       : none !important;
    padding          : 0;
  }

  .dialog-content-wrapper {
    display        : flex;
    flex-direction : column;
    max-height     : 85vh;
  }
}
