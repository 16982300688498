.nav {

  .nav-subheader {
    align-items  : center;
    display      : flex;
    font-size    : 12px;
    font-weight  : 600;
    height       : 48px;
    margin-top   : 8px;
    padding-left : 24px;
    white-space  : nowrap;
  }

  .nav-group {
    display : block;

    > .group-title {
      align-items  : center;
      display      : flex;
      font-size    : 12px;
      font-weight  : 600;
      height       : 48px;
      margin-top   : 8px;
      padding-left : 24px;
      position     : relative;
      white-space  : nowrap;
    }
  }

  .nav-item {

    .nav-link {
      align-items     : center;
      color           : currentColor;
      cursor          : pointer;
      display         : flex;
      height          : 48px;
      overflow        : hidden;
      padding         : 0 16px;
      position        : relative;
      text-decoration : none !important;
      user-select     : none;

      > .nav-link-title {
        flex        : 1 1 auto;
        white-space : nowrap;
        font-size   : 13px;
        font-weight : 500;
      }

      .nav-link-badge {
        align-items   : center;
        border-radius : 20px;
        display       : flex;
        font-size     : 11px;
        font-weight   : 600;
        height        : 20px;
        margin-left   : 8px;
        min-width     : 20px;
        padding       : 0 7px;
        transition    : opacity 0.2s ease-in-out 0.1s;

        + .collapsable-arrow {
          margin-left : 8px;
        }
      }

      &:hover {
        background-color : rgba(0, 0, 0, 0.04);
      }

      .mat-ripple-element {
        background-color : rgba(0, 0, 0, 0.04);
      }

      &.active {

        .nav-link-icon {
          opacity : 1;
        }

        .nav-link-badge {
          background : #FFFFFF !important;
          color      : rgba(0, 0, 0, 0.87) !important;
        }
      }

      .nav-link-icon {
        margin-right : 16px;
      }

      .nav-link-icon,
      .collapsable-arrow {
        font-size   : 16px;
        height      : 16px;
        line-height : 16px;
        min-height  : 16px;
        min-width   : 16px;
        width       : 16px;
      }
    }

    &.nav-collapsable {
      display : block;

      > .children {

        > .nav-item {

          > .nav-link,
          &.nav-group > .group-title,
          &.nav-group > .group-items > .nav-item > .nav-link {
            padding-left : 56px;
          }

          > .children {

            > .nav-item {

              > .nav-link,
              &.nav-group > .group-title,
              &.nav-group > .group-items > .nav-item > .nav-link {
                padding-left : 72px;
              }
            }
          }
        }
      }
    }

  }

  > .nav-item {

    &.nav-collapsable {
      background : transparent;
      transition : background 200ms ease-in-out;

      &.open {
        background : rgba(0, 0, 0, 0.12);
      }
    }
  }

  > .nav-group {

    > .group-items {

      > .nav-collapsable {
        background : transparent;
        transition : background 200ms ease-in-out;

        &.open {
          background : rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  &.vertical {

    .nav-group {

      .group-title {
        text-transform : uppercase;
      }
    }
  }

  &.horizontal {
    display        : flex;
    flex-direction : row;

    .nav-item {

      &.nav-collapsable {
        position : relative;

        .children {
          box-shadow : 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
          display    : none;
          left       : 100%;
          min-width  : 200px;
          position   : absolute;
          top        : 0;
          z-index    : 999;

          &.open {
            display : block;
          }

          .nav-link {
            padding-left : 24px !important;
          }
        }
      }
    }

    > .nav-item {

      > .nav-link {
        height : 80px;
      }

      &.nav-collapsable {
        position : relative;

        > .nav-link {
          height : 80px;

          .collapsable-arrow {
            display : none;
          }
        }

        > .children {
          left : 0;
          top  : 100%;
        }
      }
    }
  }

  &.material2, .material2 & {

    .nav-subheader {
      height : 40px;
    }

    .nav-group {

      > .group-title {
        height : 40px;
      }
    }

    .nav-item {

      .nav-link {
        border-radius : 0 20px 20px 0;
        height        : 40px;
        margin-right  : 16px;
        padding       : 0 12px 0 24px;
      }
    }
  }
}
