@use '@angular/material' as mat;
@use "@sharks/scss/sharks" as sharks-scss-sharks;

// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@mixin page-layouts-theme($theme) {

  $background : map-get($theme, background);
  $foreground : map-get($theme, foreground);

  .page-layout {

    &.carded {

      &.fullwidth {

        > .center {

          > .content-card {
            background : map-get($background, card);

            > .toolbar {
              border-bottom-color : map-get($foreground, divider);
            }
          }
        }
      }

      &.left-sidebar, &.right-sidebar {

        > .center {

          > .content-card {
            background    : map-get($background, card);
            border-radius : 8px 8px 0 0;

            > .toolbar {
              border-bottom-color : map-get($foreground, divider);
            }
          }
        }
      }
    }

    &.simple {

      &.left-sidebar, &.right-sidebar {

        > .center {

          > .content {
            background : map-get($background, card);
          }
        }

        &.inner-sidebar {

          > .content {

            > .center {

              > .content {
                background : map-get($background, card);
              }
            }
          }
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Page Layouts
// -----------------------------------------------------------------------------------------------------
$carded-header-height                 : 134px !default;
$carded-toolbar-height                : 64px !default;
$header-height                        : 120px !default;

$carded-header-height-without-toolbar : $carded-header-height - $carded-toolbar-height;

.page-layout {
  overflow : hidden;
  position : relative;

  &.carded {
    display        : flex;
    flex           : 1 0 auto;
    flex-direction : column;
    min-width      : 100%;
    width          : 100%;

    > .top-bg {
      height   : $carded-header-height;
      left     : 0;
      position : absolute;
      right    : 0;
      top      : 0;
      z-index  : 1;
    }

    &.fullwidth {

      > .center {
        display        : flex;
        flex           : 1 0 auto;
        flex-direction : column;
        height         : 100%;
        max-height     : 100%;
        max-width      : 100%;
        min-width      : 0;
        position       : relative;
        width          : 100%;
        z-index        : 2;

        > .header {
          height     : $carded-header-height-without-toolbar !important;
          max-height : $carded-header-height-without-toolbar !important;
          min-height : $carded-header-height-without-toolbar !important;
          padding    : 0 28px;
        }

        > .content-card {
          display        : flex;
          flex           : 1 0 auto;
          flex-direction : column;
          overflow       : hidden;
          @include mat.elevation(1);

          > .toolbar {
            align-items     : center;
            border-bottom   : 1px solid;
            display         : flex;
            height          : $carded-toolbar-height;
            justify-content : flex-start;
            max-height      : $carded-toolbar-height;
            min-height      : $carded-toolbar-height;
          }

          > .content {
            flex : 1 0 auto;
          }
        }
      }

      &.tabbed {

        > .center {
          min-width : 0;
          width     : 100%;

          > .header {
            flex : 1 1 auto;
          }

          > .content-card {

            > .content {
              display : flex;

              > .mat-mdc-tab-group {
                overflow : hidden;
                width    : 100%;

                .mat-mdc-tab-header {
                  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
                  .mat-tab-label {
                    height : 64px;
                  }
                }

                .mat-mdc-tab-body {
                  overflow : hidden;
                  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
                  .mat-tab-body-content {
                    overflow : hidden;

                    .tab-content {
                      height   : 100%;
                      position : relative;
                      width    : 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.inner-scroll {
        flex : 1 1 auto;

        > .center {
          flex : 1 1 auto;

          > .content-card {
            flex : 1 1 auto;

            > .content {
              flex                       : 1 1 auto;
              overflow                   : auto;
              -webkit-overflow-scrolling : touch;
            }
          }
        }

        &.tabbed {

          > .center {

            > .content-card {

              > .content {

                > .mat-mdc-tab-group {

                  .mat-mdc-tab-body {
                    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
                    .mat-tab-body-content {

                      .tab-content {
                        overflow                   : auto;
                        -webkit-overflow-scrolling : touch;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.left-sidebar, &.right-sidebar {
      flex-direction : row;

      > .sidebar {
        display        : flex;
        flex           : 1 1 auto;
        flex-direction : column;
        height         : auto;
        max-width      : 240px;
        min-width      : 240px;
        overflow       : hidden;
        width          : 240px;
        @include mat.elevation(7);

        &.locked-open {
          background : none;
          box-shadow : none;

          + .center {
            z-index : 1001;
          }

          &.left-positioned {

            + .center {
              margin-left : 0;
            }
          }

          &.right-positioned {

            + .center {
              margin-right : 0;
            }
          }
        }

        .header {
          height     : $carded-header-height;
          max-height : $carded-header-height;
          min-height : $carded-header-height;
        }

        .content {
          background : transparent;
          flex       : 1 1 auto;
        }
      }

      > .center {
        display        : flex;
        flex           : 1 1 auto;
        flex-direction : column;
        margin-left    : 32px;
        margin-right   : 32px;
        min-width      : 0;
        position       : relative;
        width          : 100%;
        z-index        : 3;

        > .header {
          display    : flex;
          height     : $carded-header-height-without-toolbar;
          max-height : $carded-header-height-without-toolbar;
          min-height : $carded-header-height-without-toolbar;
        }

        > .content-card {
          display        : flex;
          flex           : 1 1 auto;
          flex-direction : column;
          overflow       : hidden;
          @include mat.elevation(1);

          > .toolbar {
            align-items     : center;
            border-bottom   : 1px solid;
            display         : flex;
            flex            : 1 1 auto;
            height          : $carded-toolbar-height;
            justify-content : flex-start;
            max-height      : $carded-toolbar-height;
            min-height      : $carded-toolbar-height;

            .sidebar-toggle {
              border-radius : 0;
              margin        : 0 8px 0 0 !important;
              padding       : 0 !important;
            }
          }

          > .content {
            flex : 1 1 auto;
          }
        }
      }

      &.tabbed {

        > .center {
          min-width : 0;
          width     : calc(100% - 32px);

          @include sharks-scss-sharks.media-breakpoint('lt-lg') {
            width : calc(100% - 64px);
          }

          > .header {
            flex : 1 1 auto;
          }

          > .content-card {

            > .content {
              display : flex;

              > .mat-mdc-tab-group {
                overflow : hidden;

                .mat-mdc-tab-header {
                  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
                  .mat-tab-label {
                    height : 64px;
                  }
                }

                .mat-mdc-tab-body {
                  overflow : hidden;
                  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
                  .mat-tab-body-content {
                    overflow : hidden;

                    .tab-content {
                      height   : 100%;
                      position : relative;
                      width    : 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.inner-scroll {
        flex : 1 1 auto;

        > .sidebar {

          .content {
            overflow                   : auto;
            -webkit-overflow-scrolling : touch;
          }
        }

        > .center {
          flex : 1 1 auto;

          > .content-card {
            flex : 1 1 auto;

            > .content {
              flex                       : 1 1 auto;
              overflow                   : auto;
              -webkit-overflow-scrolling : touch;
            }
          }
        }

        &.tabbed {

          > .center {

            > .content-card {

              > .content {

                > .mat-mdc-tab-group {

                  .mat-mdc-tab-body {
                    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
                    .mat-tab-body-content {

                      .tab-content {
                        overflow                   : auto;
                        -webkit-overflow-scrolling : touch;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.right-sidebar {

      > .sidebar {
        order : 2;
      }

      > .center {
        order : 1;
      }
    }
  }

  &.simple {
    display        : flex;
    flex           : 1 0 auto;
    flex-direction : column;
    min-width      : 100%;
    width          : 100%;

    &.fullwidth {

      > .content {
        flex      : 1 1 auto;
        min-width : 0;
      }
    }

    &.fullwidth, &.inner-sidebar {

      > .header {
        height     : $header-height;
        max-height : $header-height;
        min-height : $header-height;
      }
    }

    &.left-sidebar, &.right-sidebar {
      flex-direction : row;

      > .sidebar {
        max-width : 240px;
        min-width : 240px;
        overflow  : hidden;
        width     : 240px;
        @include mat.elevation(7);

        &.locked-open {
          background : none;
          box-shadow : none;

          + .center {
            z-index : 1001;
          }

          &.left-positioned {

            + .center {
              margin-left : 0;
            }
          }

          &.right-positioned {

            + .center {
              margin-right : 0;
            }
          }
        }

        .content {
          flex : 1 1 auto;
        }
      }

      > .center {
        display        : flex;
        flex           : 1 1 auto;
        flex-direction : column;
        min-width      : 0;
        position       : relative;
        width          : 100%;
        z-index        : 3;
        @include mat.elevation(1);

        > .header {
          height     : $header-height;
          max-height : $header-height;
          min-height : $header-height;
        }

        > .content {
          flex : 1 0 auto;
        }
      }

      &.inner-scroll {
        flex : 1 1 auto;

        > .sidebar {

          .content {
            overflow                   : auto;
            -webkit-overflow-scrolling : touch;
          }
        }

        > .center {
          flex                       : 1 1 auto;
          overflow                   : auto;
          -webkit-overflow-scrolling : touch;
        }
      }

      &.inner-sidebar {
        flex-direction : column;
        height         : 100%;
        overflow       : hidden;

        > .content {
          display    : flex;
          min-height : 0;

          > .sidebar {

            &.locked-open {
              background : none;
              box-shadow : none;
            }

            .content {
              overflow                   : auto;
              -webkit-overflow-scrolling : touch;
            }
          }

          > .center {
            flex                       : 1 1 auto;
            overflow                   : auto;
            -webkit-overflow-scrolling : touch;

            > .content {
              border-radius : 8px;
              @include mat.elevation(1);
            }
          }
        }
      }
    }

    &.right-sidebar {

      > .sidebar {
        order : 2;
      }

      > .center {
        order : 1;
      }

      &.inner-sidebar {

        > .content {

          > .sidebar {
            order : 2;
          }

          > .center {
            order : 1;
          }
        }
      }
    }

    &.tabbed {
      min-height : 100%;

      > .header {
        height     : $header-height;
        max-height : $header-height;
        min-height : $header-height;
      }

      > .content {

        > .mat-mdc-tab-group {
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-labels {
            padding : 0 24px;
          }
        }
      }
    }
  }

  &.blank {
    min-height : 100%;
    width      : 100%;
  }

  @include sharks-scss-sharks.media-breakpoint('xs') {

    &.carded {

      &.fullwidth {

        > .center {
          padding : 0 16px;
        }
      }

      &.left-sidebar, &.right-sidebar {

        > .center {
          margin : 0 16px;
        }
      }
    }
  }
}


