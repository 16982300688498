@use "@sharks/scss/sharks" as sharks-scss-sharks;

@mixin sharks-mapbox-theme($theme) {

  .mapboxgl-popup {
    max-width : none !important;
  }

  .mapboxgl-popup-content {
    background-color : transparent;
    padding          : 0;
  }

  .mapboxgl-popup-tip {
    display : none;
  }
}
