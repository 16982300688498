// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
@mixin material-theme($theme) {

  $background : map-get($theme, background);
  $foreground : map-get($theme, foreground);

  // -----------------------------------------------------------------------------------------------------
  // @ Avatar
  // -----------------------------------------------------------------------------------------------------
  .avatar {
    color : map-get($foreground, text);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Forms
  // -----------------------------------------------------------------------------------------------------
  .form-wrapper {
    background-color : map-get($background, card);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Navigation - Simple
  // -----------------------------------------------------------------------------------------------------
  .navigation-simple {

    .item {

      &.selected {
        background-color : map-get($background, hover);
      }
    }

    .subheader {
      border-top : 1px solid map-get($foreground, divider);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Pagination - Simple
  // -----------------------------------------------------------------------------------------------------
  .simple-pagination {
    background : map-get($background, card);

    .pagination-item {
      color : map-get($foreground, secondary-text);

      &:hover {
        color : map-get($foreground, text);
      }

      &.disabled {
        color : map-get($foreground, disabled-text);
      }
    }
  }

  // -------------------------------------------------------------------------------------------------
  // @ Table - Simple
  // -------------------------------------------------------------------------------------------------
  .simple-table-container {
    background : map-get($background, card);
  }

  table {

    &.simple {

      thead {

        tr {

          th {
            color         : map-get($foreground, secondary-text);
            border-bottom : 1px solid map-get($foreground, divider);
          }
        }
      }

      tbody {

        tr {

          td {
            border-bottom : 1px solid map-get($foreground, divider);
          }
        }
      }

      &.clickable {

        tbody {

          tr {

            &:hover {
              background-color : map-get($background, hover);
            }
          }
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Avatar
// -----------------------------------------------------------------------------------------------------
.avatar {
  border-radius : 50%;
  font-size     : 17px;
  font-weight   : 600;
  height        : 40px;
  line-height   : 40px;
  margin        : 0 8px 0 0;
  min-width     : 40px;
  text-align    : center;
  width         : 40px;

  &.square {
    border-radius : 0;
  }

  &.small {
    height      : 20px;
    line-height : 20px;
    min-width   : 20px;
    width       : 20px;
  }

  &.big {
    height      : 72px;
    line-height : 72px;
    min-width   : 72px;
    width       : 72px;
  }

  &.huge {
    height      : 96px;
    line-height : 96px;
    min-width   : 96px;
    width       : 96px;
  }
}

.avatar-wrapper {
  position : relative;

  .avatar {
    margin-bottom : 0;
    margin-top    : 0;
  }

  mat-icon.status {
    left     : 28px;
    position : absolute;
    top      : 28px;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Forms
// -----------------------------------------------------------------------------------------------------
.form-wrapper {
  padding : 16px;

  .form-title {
    font-size : 21px;
    padding   : 8px 0;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Navigation - Simple
// -----------------------------------------------------------------------------------------------------
.navigation-simple {

  .item {
    cursor         : pointer;
    height         : 48px;
    line-height    : 48px;
    margin         : 0;
    max-height     : 48px;
    padding        : 0 24px;
    position       : relative;
    text-align     : left;
    text-transform : none;

    mat-icon {
      margin : 0 16px 0 0;
    }

    .title {
      font-size   : 13px;
      font-weight : 600;
      line-height : 1;
    }
  }

  .subheader {
    font-size   : 13px;
    font-weight : 600;
    margin-top  : 8px;
  }

  mat-divider {
    margin : 8px 0;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Pagination - Simple
// -----------------------------------------------------------------------------------------------------
.simple-pagination {
  border-radius  : 4px;
  display        : inline-flex;
  flex-direction : row;
  padding        : 0 8px;
  position       : relative;
  @include mat.elevation(1);

  .pagination-item {
    align-items     : center;
    border-radius   : 0;
    display         : flex;
    font-weight     : normal;
    justify-content : center;
    line-height     : 56px;
    margin          : 0;
    min-height      : 56px;
    min-width       : 48px;

    &.active {
      cursor : default;
    }

    &.disabled {
      cursor : default;
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Table - Simple
// -----------------------------------------------------------------------------------------------------
.simple-table-container {

  .table-title {
    font-size : 20px;
    padding   : 24px;
  }
}

table {

  &.simple {
    border         : none;
    border-spacing : 0;
    text-align     : left;
    width          : 100%;

    thead {

      tr {

        th {
          font-weight : 600;
          padding     : 16px 8px;
          white-space : nowrap;

          &:first-child {
            padding-left : 24px;
          }

          &:last-child {
            padding-right : 24px;
          }
        }
      }
    }

    tbody {

      tr {

        td {
          padding : 16px 8px;

          &:first-child {
            padding-left : 24px;
          }

          &:last-child {
            padding-right : 24px;
          }
        }

        &:last-child {

          td {
            border-bottom : none;
          }
        }
      }
    }

    &.clickable {

      tbody {

        tr {
          cursor : pointer;
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Tooltip
// -----------------------------------------------------------------------------------------------------

.mat-mdc-tooltip {
  white-space : pre-wrap !important;
  word-wrap   : break-word !important;
}
