@use '@angular/material' as mat;
@include mat.core();
@import "@sharks/sharks-icons/styles.css";
@import "datepicker-theming";
@import 'perfect-scrollbar/css/perfect-scrollbar';

@import "sharks";
@import "theme";

@import "partials/scrollbars";
@import "partials/helpers";
@import "partials/general";
@import "partials/icons";
@import "partials/colors";
@import "partials/docs";
@import "partials/material";
@import "partials/angular-material-fix";
@import "partials/typography";
@import "partials/page-layouts";
@import "partials/cards";
@import "partials/navigation";
@import "partials/forms";
@import "partials/forms";
@import "partials/perfect-scrollbar";
@import "partials/animation";
@import "partials/map";
@import "partials/fullcalendar";
@import "partials/mapbox";

@import "@sharks/components/support/confirm-dialog/confirm-dialog.component";
@import "@sharks/components/document/email-dialog/email-dialog.component";
@import "@sharks/components/document/file-upload-dialog/file-upload-dialog.component";
@import "@sharks/components/document/file-viewer-dialog/file-viewer-dialog.component";
@import "@sharks/components/support/loading-dialog/loading-dialog.component";
@import "@sharks/components/support/message-snackbar/message-snackbar.component";
