@mixin sharks-icons-searcher-theme($theme) {

  $accent : map-get($theme, accent);

  sharks-icons-searcher {

    .icon-holder {

      &:hover {
        background-color : map-get($accent, 50);
        color            : map-get($accent, default-contrast);
        border-radius    : 4px;
      }
    }
  }
}
