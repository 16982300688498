@mixin profile-theme($theme) {

  $background : map-get($theme, background);
  $accent     : map-get($theme, accent);

  apps-profile-about {

    .profile-box {

      .content {
        background-color : map-get($background, card);
      }

      .mat-mdc-icon-button:hover {
        background-color : map-get($accent, default);
        color            : white;
      }
    }
  }
}
