@use '@angular/material' as mat;

// -----------------------------------------------------------------------------------------------------
// @ Body scroll lock
// -----------------------------------------------------------------------------------------------------
html,
body {
  display    : flex;
  flex       : 1 0 auto;
  height     : 100%;
  margin     : 0;
  max-height : 100%;
  min-height : 100%;
  overflow   : hidden;
  padding    : 0;
  width      : 100%;
}

// -----------------------------------------------------------------------------------------------------
// @ Boxed body
// -----------------------------------------------------------------------------------------------------
body {

  &.boxed {
    margin    : 0 auto;
    max-width : 1200px;

    @include mat.elevation(8);
  }
}

/*----------------------------------------------------------------*/
/*  @ Text rendering & box sizing
/*----------------------------------------------------------------*/
* {
  box-sizing                  : border-box;
  -webkit-tap-highlight-color : transparent;
  -moz-text-rendering         : optimizeLegibility;
  -ms-text-rendering          : optimizeLegibility;
  -o-text-rendering           : optimizeLegibility;
  -webkit-text-rendering      : optimizeLegibility;
  text-rendering              : optimizeLegibility;

  &:before, &:after {
    box-sizing : border-box;
  }

  &:focus {
    outline : none;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Responsive images
// -----------------------------------------------------------------------------------------------------

img {
  border         : none;
  height         : auto;
  max-width      : 100%;
  vertical-align : top;
}

// -----------------------------------------------------------------------------------------------------
// @ Input
// -----------------------------------------------------------------------------------------------------
input {
  background-color : transparent;
  border           : none;
}

// -----------------------------------------------------------------------------------------------------
// @ ngx-extended-pdf-viewer
// -----------------------------------------------------------------------------------------------------

#toolbarViewer {
  padding : 0 12px;
}
