button,
input[type=email],
input[type=tel],
input[type=text],
input[type=password],
input[type=image],
input[type=submit],
input[type=button],
input[type=search],
textarea {
  -moz-appearance    : none;
  -webkit-appearance : none;
  appearance         : none;
  color              : currentColor;
  outline            : none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance : none;
  margin             : 0;
}

input[type=number] {
  -moz-appearance : textfield;
}
