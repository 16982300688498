@mixin sharks-notification-theme($theme) {

  $accent : map-get($theme, accent);

  .notification-item {

    &:hover {
      background-color : map-get($accent, 200);
    }
  }
}
