.ps {
  position : relative;

  > .ps__rail-x {
    z-index : 99999;
  }

  > .ps__rail-y {
    left    : auto !important;
    z-index : 99999;
  }
}
