@mixin mat-theme($theme) {

  $accent     : map-get($theme, accent);
  $warn       : map-get($theme, warn);
  $foreground : map-get($theme, foreground);

  // -----------------------------------------------------------------------------------------------------
  // @ Mat Form
  // -----------------------------------------------------------------------------------------------------
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-focused .mat-form-field-required-marker {
    color : map-get($warn, default);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-color : transparent;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Mat Select, Mat List
  // -----------------------------------------------------------------------------------------------------
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value {
    color : currentColor !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-disabled .mat-select-value {
    color : map-get($foreground, disabled-text) !important;
  }

  //Angular 17
  .cdk-overlay-pane:has(>.mat-mdc-select-panel) {
    min-width : fit-content !important;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Mat Calendar
  // -----------------------------------------------------------------------------------------------------

  .mat-calendar-body-selected {
    background-color : map_get($accent, default) !important;
  }

  .mat-calendar-cell-semi-selected, .mat-calendar-body-begin-range:not(.mat-calendar-body-end-range), .mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
    background-color : map_get($accent, 100) !important;
  }
}

html {

  &.cdk-global-scrollblock {
    overflow : hidden !important;
    position : relative !important;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Button, Mat Icon
// -----------------------------------------------------------------------------------------------------
button {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper {
    line-height : normal;
  }
}

.mat-icon {
  font-weight : 400 !important;
  line-height : 1;
}

.mat-mdc-icon-button {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-ripple {
    border-radius : 50%;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
  border-bottom : solid 48px !important;
}

//Angular 17
.btn--h-auto {
  &.mat-mdc-raised-button.mat-mdc-raised-button {
    min-height : var(--mdc-protected-button-label-text-weight);
    height     : auto;
  }

  &.mdc-button.mdc-button {
    display : block;
  }

  --mdc-outlined-button-container-height : #{auto};
}

.mat-mdc-outlined-button > .mat-icon.mat-icon {
  font-size : 24px;
}

.mdc-button .mdc-button__label {
  vertical-align        : sub;
  font-feature-settings : 'cv11';
}

.mat-mdc-button .mdc-button__label {
  display : flex;
}

.mat-mdc-unelevated-button.mat-mdc-unelevated-button > .mat-icon {
  font-size                        : 24px;
  --mat-filled-button-icon-spacing : 0px;
  --mat-filled-button-icon-offset  : 0px;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Dialog
// -----------------------------------------------------------------------------------------------------
.mat-mdc-dialog-container {
  -webkit-overflow-scrolling : touch;
}

// Angular 17
.mdc-dialog__title::before {
  display : none !important;
}

.confirm-dialog .mat-mdc-dialog-surface {
  padding : 24px;
}

.loading-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow       : none;
  background-color : transparent;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color : rgba(0, 0, 0, 0.87) !important;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Checkbox, Mat Radio Button
// -----------------------------------------------------------------------------------------------------
.mat-mdc-checkbox {
  font-size : 16px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-frame {
  border-color : unset !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color : #A9A9A9 !important;
}

.mat-mdc-radio-button {
  font-size : 16px;
}

.mdc-checkbox__checkmark-path.mdc-checkbox__checkmark-path {
  stroke : white;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Form
// -----------------------------------------------------------------------------------------------------
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-underline {
  background-color : rgba(0, 0, 0, 0.12);
}

.mat-mdc-form-field {
  font-size : 14px !important;

  &.mat-form-field-type-mat-select {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
      display : inline-flex;
      width   : auto;
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-trigger {
        align-items : center;
        display     : inline-flex;
        width       : 100%;
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-select-value {
          display      : flex;
          margin-right : 8px;
          max-width    : none;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-select-arrow-wrapper {
          display : inline-flex;
        }
      }
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper, .mat-form-field-flex, .mat-form-field-infix {
  height : 100%;
}

.mat-mdc-select-placeholder {
  color   : currentColor !important;
  opacity : 0.42;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Stepper
// -----------------------------------------------------------------------------------------------------
mat-horizontal-stepper, mat-vertical-stepper {

  mat-step-header {

    mat-icon {
      color      : inherit !important;
      height     : 16px !important;
      min-height : 0 !important;
      min-width  : 0 !important;
      width      : 16px !important;
    }
  }
}

mat-vertical-stepper {
  padding : 16px 0;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Chip
// -----------------------------------------------------------------------------------------------------
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
mat-chip {

  mat-icon {
    min-height : 0 !important;
    min-width  : 0 !important;
  }
}

.mat-mdc-standard-chip.mat-mdc-standard-chip {
  min-height : 24px !important;
  padding    : 2px 12px 2px 12px !important;
  height     : auto;
}

.mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove {
  color : currentColor !important;
}

.mat-mdc-chip-list-wrapper {
  width : 100%;
}

.mat-mdc-chip.mat-mdc-standard-chip .mdc-evolution-chip__text-label.mdc-evolution-chip__text-label {
  font-weight : 600;
  color       : inherit;
  font-size   : inherit;
}

.mdc-evolution-chip-set .mdc-evolution-chip.mdc-evolution-chip {
  margin-top    : 4px;
  margin-bottom : 4px;
}

.mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
  margin : -4px;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Card
// -----------------------------------------------------------------------------------------------------
.mat-mdc-card-image {
  max-width : none !important;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Table
// -----------------------------------------------------------------------------------------------------
.mat-mdc-row {
  min-height : 64px !important;
}

.mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom-color : var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12)) !important;
  border-bottom-width : var(--mat-table-row-item-outline-width, 1px) !important;
  border-bottom-style : solid !important;
}

.mat-mdc-cell:first-of-type, .mat-mdc-header-cell:first-of-type {
  padding-left : 12px !important;
}

.mat-mdc-cell:last-of-type, .mat-mdc-header-cell:last-of-type {
  padding-right : 12px !important;
}

.mdc-data-table__cell.mdc-data-table__cell {
  color : #1E293B !important;
}

.mdc-data-table__cell.mdc-data-table__cell, .mdc-data-table__header-cell.mdc-data-table__header-cell {
  padding : 0;

  .mat-mdc-icon-button.mat-mdc-icon-button {
    padding : 0;
  }
}

.mat-mdc-header-cell {
  color                          : #64748B !important;
  --mat-select-trigger-text-size : 12px;
}

.mat-mdc-header-cell {
  .mat-mdc-input-element, .sharks-date-filter__label {
    font-size : 12px;
  }

  .mat-mdc-select {
    --mat-select-trigger-text-size : 12px;
  }

}


.mat-mdc-header-row {
  --mat-table-header-headline-size : 13px;
  background-color                 : #F1F5F9 !important;
  color                            : #64748B !important;
  box-shadow                       : 0 3px 3px -3px rgb(0 0 0 / .1) !important;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Select, Mat List
// -----------------------------------------------------------------------------------------------------

.mat-mdc-option {
  font-size   : 14px !important;
  font-weight : 600;
  height      : 3em !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
.mat-autocomplete-panel {
  min-width : 250px !important;
}

.mat-mdc-list-base .mat-mdc-list-item, .mat-list-base .mat-list-option {
  font-size                            : 14px !important;
  height                               : fit-content !important;
  min-height                           : 30.4px;
  --mdc-list-list-item-label-text-size : 14px;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-mdc-list-base .mat-mdc-list-item .mat-list-item-content {
  padding : 0 !important;
}

//Angular 17
.mat-mdc-list-item-unscoped-content {
  align-items : center;
}

.mat-mdc-select {
  --mat-select-trigger-text-size : 14px;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Menu
// -----------------------------------------------------------------------------------------------------

.mat-mdc-menu-panel {
  max-width : 1080px !important;
}

.mat-mdc-menu-content:not(:empty) {
  padding : 0 !important;
}

// Angular 17
.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width : none;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Toggle
// -----------------------------------------------------------------------------------------------------

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding : 0 4px !important;
  width   : 100%;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content span {
  font-feature-settings : 'cv11';
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Toolbar
// -----------------------------------------------------------------------------------------------------

.mat-toolbar-row, .mat-toolbar-single-row, .mat-toolbar-multiple-rows {
  height     : 52px !important;
  min-height : 52px !important;;
}

// -----------------------------------------------------------------------------------------------------
// @ Slide toggle
// -----------------------------------------------------------------------------------------------------
.mat-mdc-slide-toggle * {
  color : black;

  .mdc-switch:enabled .mdc-switch__track::before {
    background : rgba(0, 0, 0, .38);
  }

  .mdc-switch__handle-track {
    .mdc-switch__shadow {
      .mdc-elevation-overlay {
        background : white;
      }
    }

    .mdc-switch__icons {
      display : none;
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Snackbar
// -----------------------------------------------------------------------------------------------------
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  border           : none !important;
  box-shadow       : none !important;
  background-color : transparent !important;
}

// -----------------------------------------------------------------------------------------------------
// @ Mat Paginator
// -----------------------------------------------------------------------------------------------------
.mat-mdc-paginator-outer-container .mat-mdc-paginator-container {
  height : 72px !important;
}
